.typo-tooltip {
  font-family: Inter, sans-serif;
  font-size: 13.146px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.329px;
}

.tooltip {
  --tooltip-background: #322F36;
  --tooltip-color: #fff;

  composes: typo-tooltip;

  background: var(--tooltip-background);
  color: var(--tooltip-color);
  opacity: 0;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  z-index: 1;
  transition: opacity 125ms;

  &[data-popper-placement^='top'] {
    .arrow {
      bottom: -5px;
    }
  }

  &[data-popper-placement^='bottom'] {
    .arrow {
      top: -5px;
    }
  }

  &[data-popper-placement^='left'] {
    .arrow {
      right: -5px;
    }
  }

  &[data-popper-placement^='right'] {
    .arrow {
      left: -5px;
    }
  }

  &.isEnter {
    opacity: 0;
  }

  &.isEnterActive,
  &.isEnterDone,
  &.isExit {
    opacity: 1;
  }

  &.isExitActive {
    opacity: 0;
  }

  .arrow {
    --size: 0.5rem;

    width: var(--size);
    height: var(--size);

    &:before {
      content: "";
      width: var(--size);
      height: var(--size);
      position: absolute;
      z-index: -1;
      background: var(--tooltip-background);
      top: 0;
      left: 0;
      visibility: visible;
      transform-origin: center center;
      transform: translateX(0) translateY(-0.1rem) rotate(45deg);
    }
  }
}
